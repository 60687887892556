.RepDateRangePicker {
  position: relative;
  width: fit-content;
  height: 100%;
  cursor: pointer;

  .Preview {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-color: #f0f4f7;
    justify-content: space-between;
    width: 20rem;
    height: 4rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &.Closed {
      border-radius: 0.5rem;
    }
    &:focus {
      box-shadow: 0 0 0 0.15rem transparentize($color: #1d2939, $amount: 0.7);
      outline: 0 !important;
    }

    .CalendarIcon {
      width: 1rem;
      height: 1rem;
      margin-left: 1.2706rem;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 0.5rem;
    }
    .DateRangeLabel {
      cursor: pointer !important;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      color: #787a7b;
      line-height: 125%;
      display: flex;
      align-items: center;
      cursor: default;
      text-align: center;
      padding-right: 0.875rem;
      white-space: nowrap;
      &.default {
        padding-left: 1.5rem;
        padding-right: 2.6rem;
      }
    }
    .ShowRangePickerButton {
      margin-right: 1.25rem;
      cursor: pointer;
    }
  }
  .DateRange {
    background-color: #f0f4f7;
    position: absolute;
    z-index: 200;
    width: 20rem;
    height: 20rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}
