html,
body {
  background: white;
  padding: 0;
  margin: 0;
}

p,
b,
a {
  color: inherit;
  text-decoration: none;
  font-size: 0.875rem;
}

* {
  box-sizing: border-box;
}
