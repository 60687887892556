.LoginPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  justify-content: center;
  align-content: center;
  height: 100vh;
  background: url(https://uploads-ssl.webflow.com/649ae7b782adecd34eaa6653/649ae9cb1e2589948c11d298_ravi-patel-VMGAbeeJTKo-unsplash.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    width: 10rem;
  }

  .button {
    padding: 5rem;
  }

  .RepLoginForm {
    border-radius: 0.5rem;
    margin-left: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #252248;
    background: linear-gradient(180deg, #e9fbf4 0%, #c2ecfe 100%);

    padding: 5rem;

    img {
      object-fit: contain;
      margin-bottom: 2rem;
    }

    input {
      font-size: 1rem;
      margin-bottom: 20px;
      border-radius: 0.5rem;
      padding: 1rem;
      border: 0.5px solid #a8a8a8;
      background: #ffffff;
    }

    button {
      margin-top: 1rem;
      cursor: pointer;
      font-size: 1rem;
      color: #252248;
      padding: 0.7rem 1rem;
      border: 1px solid;
      border-radius: 2rem;
      background: white;
      text-transform: uppercase;
      font-size: 0.9rem;
      font-weight: lighter;

      &:hover {
        background: #252248;
        color: #ffffff;
      }
    }
  }

  .RepLoginErrorContainer {
    display: flex;
    flex-direction: "row";
    justify-content: center;
    padding-top: 1rem;
    width: 18.75rem;

    .RepLoginErrorMessage {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      color: #f04438;
    }
  }
}