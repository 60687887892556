.TaxonomySection {
  .TaxonomySectionTitle {
    color: #003058;
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 1rem;
    text-transform: capitalize;
  }

  .TaxonomySectionData {
  }
}
